import React, { Component } from 'react';
import AttachmentPartPDF from './AttachmentPartPDF';
import ModelingTabs from './ModelingTabs';
import LoadingSpinner from './LoadingSpinner';

class CommentPartTextOrPDF extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasSupportedFile: false,
			viewModelingOutputs: true,
			showAttachment: false,
			pdfFound: false,
			fileUrl: '',
			fileType: '',
			machine: '',
			loaded: false,
		};
	}

	componentDidMount() {
		this.getMachineInfo();
		if (this.props.commentId && this.props.attNum !== undefined) {
			this.resetStates();
			if (this.props.attNum === 0) {
				this.setState({ loaded: true });
			} else {
				this.getHasPdf();
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (
			(prevProps.commentId !== this.props.commentId && this.props.attNum !== 0) ||
			prevProps.attNum !== this.props.attNum
		) {
			this.resetStates();
			if (this.props.attNum === 0) {
				this.setState({ loaded: true });
			} else {
				this.getHasPdf();
			}
		}
	}


	resetStates = () => {
		this.setState({
			hasSupportedFile: false,
			showAttachment: false,
			pdfFound: false,
			fileUrl: '',
			fileType: '',
			loaded: false,
		});
	}

	getMachineInfo = () => {
		fetch('/api/machine')
			.then((response) => response.json())
			.then((json) => this.setState({ machine: json.machine }));
	};

	getHasPdf = () => {
		const { office, commentId, attNum } = this.props;
		console.log(attNum)
		if (attNum >= 1) {
			console.log('not 0')

			fetch(`/api/${office}/hasSupportingFile/${commentId}/${attNum}`)
				.then((response) => response.json())
				.then((json) => {
					this.setState({
						hasSupportedFile: json.hasSupportedFile,
						pdfFound: json.pdfFound,
						showAttachment: json.hasSupportedFile,
						loaded: !json.hasSupportedFile,
					}, () => {
						if (json.hasSupportedFile) {
							this.fetchAttachmentUrl(json.supportedFileType);
							this.setState({viewModelingOutputs: false})
						}
					});
				});
		}
	};

	fetchAttachmentUrl = (supportedFileType) => {
		const { commentId, attNum, office } = this.props;
		const { machine } = this.state;

		fetch(`${machine}/api/${office}/getSupportingFile/${commentId}/${attNum}/${supportedFileType}`)
			.then((response) => response.json())
			.then((json) => {
				if (json.url) {
					this.setState({ 
						fileUrl: json.url, 
						fileType: json.fileType,
						loaded: true,
					});
				}
			})
			.catch((error) => {
				console.error('Error fetching file URL:', error);
				this.setState({ loaded: true }); // to stop loading on error
			});
	};

	setShowPdf = () => {
		this.setState((prevState) => ({
			viewModelingOutputs: !prevState.viewModelingOutputs
		}));
	};

	render() {
		const { attNum, commentId, filterText, office, hasText, details, user, docketId } = this.props;
		const { viewModelingOutputs, loaded, fileUrl, fileType } = this.state;

		if (!loaded) {
			return (
				<LoadingSpinner/>
			);
		}
		else{

			if (viewModelingOutputs) {
				return (
					<ModelingTabs
						details={details}
						commentId={commentId}
						filterText={filterText}
						office={office}
						setShowPdf={this.setShowPdf}
						hasText={hasText}
						hasSupportedFile={this.state.hasSupportedFile}
						showPdf={this.state.viewModelingOutputs}
						attNum={attNum}
						user={user}
						docketId={docketId}
					/>
				);
			}

			else{

			return (
				<AttachmentPartPDF
					url={fileUrl}
					fileType={fileType}
					commentId={commentId}
					filterText={filterText}
					office={office}
					setShowPdf={this.setShowPdf}
					hasText={hasText}
					attNum={attNum}
				/>
			);
		}
		}
	}
}

export default CommentPartTextOrPDF;
