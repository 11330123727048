import React, { Component } from 'react';
import { Badge, Button, Dropdown, Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import {components} from 'react-select'
import { TextAnnotator } from 'react-text-annotate'
import { v4 as uuidv4 } from 'uuid';
import Split from 'react-split'


const TAG_COLORS = {
    Agreement: '#DBF3DF',
    Disagreement: '#F8DFE2',
    Clarification: '#F2E4D4',
    Recommendation: '#E1F3F8',
    Theme: '#E7E9FA'
};
class ModelingTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,

            recs_exist: false,
            clars_exist: false,
            agrs_exist: false,
            disagrs_exist: false,
            themes_exist: false,
            isprocessing: false,

            cmt: {},
            annotationPlaceholder: 'Select to add an annotation...',
            extractedTag: '',

            themes: [],
            recommendations: [],
            clarifications: [],
            agreements: [],
            disagreements: [],
            selectedTabs: ['Keywords'],
            value: [],
            filteredEntity: ["Recommendation", "Agreement", "Clarification", "Disagreement"],
            selectedHighlightTags: [],
            onChangeInfo: [],
            creatableDefaultOptions: [],
            selectedOptions: [],
            selectableThemes: [],
            selectableThemess: [],
            ariaLiveMessage: '',
            setAriaLiveMessage: ''
        };
    }

    _isMounted = false;

    updateAriaLiveRegion = (message) => {
        this.setState({ariaLiveMessage: message});
    };
    getModeling = (callback) => {
        console.log(callback)
        console.log('Get modeling fired')
        this._isMounted = true;
        this.setState({
            value: [

            ],
            selectedTabs: ['Keywords']
        })

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                commentId: this.props.commentId,
                attNum: this.props.attNum,
            })
        };

        const fetchPromises = [
            fetch('/api/' + this.props.office + '/commentReco', requestOptions)
                .then(response => response.json())
                .then(json => {
                    if (this._isMounted) {
                        if (json.recos.length !== 0) {
                            this.setState({ recs_exist: true })
                        } else {
                            this.setState({ recs_exist: false })
                        }
                        this.setState({ recommendations: json.recos })
                        console.log(json.recos)
                    }
                }),
            fetch('/api/' + this.props.office + '/commentClar', requestOptions)
                .then(response => response.json())
                .then(json => {
                    if (this._isMounted) {
                        if (json.clars.length !== 0) {
                            this.setState({ clars_exist: true })
                        } else {
                            this.setState({ clars_exist: false })
                        }
                        this.setState({ clarifications: json.clars })
                        console.log(json.clars)

                    }
                }),
            fetch('/api/' + this.props.office + '/commentAgr', requestOptions)
                .then(response => response.json())
                .then(json => {
                    if (this._isMounted) {
                        if (json.agrs.length !== 0) {
                            this.setState({ agrs_exist: true })
                        } else {
                            this.setState({ agrs_exist: false })
                        }
                        this.setState({ agreements: json.agrs })
                    }
                }),
            fetch('/api/' + this.props.office + '/commentDisagr', requestOptions)
                .then(response => response.json())
                .then(json => {
                    if (this._isMounted) {
                        if (json.disagrs.length !== 0) {
                            this.setState({ disagrs_exist: true })
                        } else {
                            this.setState({ disagrs_exist: false })
                        }
                        this.setState({ disagreements: json.disagrs })
                    }
                }),
            fetch('/api/' + this.props.office + '/commentThemesSentences', requestOptions)
                .then(response => response.json())
                .then(json => {
                    if (this._isMounted) {
                        if (json.themes.length !== 0) {
                            this.setState({ themes_exist: true })
                        } else {
                            this.setState({ themes_exist: false })
                        }
                        this.setState({ themes: json.themes })
                        console.log(json.themes)


                        const themesArray = json.themes.map((item) => item.theme);
                        const themessArray = json.themes.map((item) => item.theme + 's');
                        const uniqueThemesTagArray = [...new Set(themesArray)];
                        const pluralThemesTagsArray = [...new Set(themessArray)];

                        this.setState({ selectableThemes: uniqueThemesTagArray })
                        this.setState({ selectableThemess: pluralThemesTagsArray })

                    }
                }),

        ];

        Promise.all(fetchPromises)
            .then(() => {
                this.generateAnnotationsOptions()
                this.setState({ activeTab: 0 });
                if (typeof callback === 'function') {
                    callback();
                }
            })
            .catch(error => {
                console.error('Error occurred:', error);
            });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        window.scrollTo(0, 0);
        this.getCommentPart()
        this.getData()
    }

    componentDidUpdate(prevProps) {
        if ((this.props.office !== '' && this.props.commentId !== '' && this.props.attNum !== '')
            && ((this.props.attNum !== -1 && this.props.attNum !== prevProps.attNum) || (this.props.commentId !== prevProps.commentId))) {
            this.getData();
            this.setState({
                creatableDefaultOptions: [],
                recommendations: [],
                clarifications: [],
                agreements: [],
                disagreements: [],
                themes: [],
                selectedTabs: ['Keywords'],
                values: [],
                selectedOptions: [],
                selectableThemes: [],
                selectableThemess: [],
            })

        }
    }

    getData = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                docketId: this.props.docketId,
                comment_id_att: this.props.commentId + '_' + this.props.attNum,
                comment_id: this.props.commentId,
                attNum: this.props.attNum,
                user: this.props.user,
            })
        };
        this.getCommentPart()

        fetch('/api/get_darc_feedback', requestOptions)
            .then(response => response.json())
            .then(json => {
                if (this._isMounted) {
                    if (Object.keys(json).length === 0) {
                        this.getModeling(this.updateValue);
                    } else {
                        console.log("Item exists in dynamodb")
                        this.getDARCFeedback(json)

                    }
                }
            });
    }
    getDARCFeedback = (json) => {
        console.log(this.state.recommendations.length)

        this.getCommentPart()

        const onlyThemeObjs = this.filterDARC(json)
        const themesArray = onlyThemeObjs.map((obj) => obj.tag);
        const themessArray = onlyThemeObjs.map((obj) => obj.tag + "s");
        const uniqueThemesTagArray = [...new Set(themesArray)];
        const pluralThemesTagsArray = [...new Set(themessArray)];


        this.setState({
            value: json,
            recommendations: this.getDARCValues("Recommendation", json),
            agreements: this.getDARCValues("Agreement", json),
            disagreements: this.getDARCValues("Disagreement", json),
            clarifications: this.getDARCValues("Clarification", json),
            themes: onlyThemeObjs,
            selectableThemes: uniqueThemesTagArray,
            selectableThemess: pluralThemesTagsArray,

        }, () => {
            this.generateAnnotationsOptions();
        });
    }

    getDARCValues = (entity, obj) => {
        const arr = obj.filter(item => item.tag === entity);
        return arr
    }

    getCommentPart = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                commentId: this.props.commentId,
                filterText: this.props.filterText,
                attNum: this.props.attNum,
                maxLength: Number.MAX_SAFE_INTEGER,
            })
        };
        fetch('/api/' + this.props.office + '/commentPart', requestOptions)
            .then((response) => {
                if (this._isMounted)
                    return response.json()
            })
            .then((json) => {
                if (this._isMounted) {
                    const textWithTags = json.comment;
                    const textWithoutTags = textWithTags.replace(/<\/?(?:em|b|br)>/g, '');
                    json.comment = textWithoutTags;

                    this.setState({ cmt: json }, () => {
                        if (this.props.filterText !== '') {
                            console.log(json.comment)
                            this.codyHighlight(json.comment);
                        }
                    });
                }
            })
    }
    updateValue = () => {
        // Function to be called after getModeling is finished
        console.log('Update function called!');

        const indices = this.findSubstringIndices(this.state.cmt.comment);
        console.log(indices)
        let fin = [...this.state.value, ...indices]
        let uniqueValues = new Set(fin);
        fin = [...uniqueValues];
        this.tempFunction(fin)
    };

    findSubstringIndices = (string) => {
        console.log(string)

        const darcCategories = [
            { category: 'recommendations', tag: 'Recommendation', color: '#E1F3F8', prop: 'reco_sent' },
            { category: 'clarifications', tag: 'Clarification', color: '#F2E4D4', prop: 'clar_sent' },
            { category: 'agreements', tag: 'Agreement', color: '#DBF3DF', prop: 'agree_sent' },
            { category: 'disagreements', tag: 'Disagreement', color: '#fad0cd', prop: 'disagree_sent' },
        ];

        const themeCategory = {
            category: 'themes',
            color: TAG_COLORS.Theme,
            prop: 'sentence',
        };

        const allCategories = [...darcCategories, themeCategory];
        const indices = [];

        for (const { category, tag, color, prop } of allCategories) {
            if (category === 'themes') {
                const categoryArray = this.state[category];
                for (const substring of categoryArray) {
                    const sent = substring[prop];

                    const result = sent.trim()

                    const theme = substring['theme']
                    const startIndex = string.indexOf(result);
                    if (startIndex !== -1) {
                        const endIndex = startIndex + result.length;
                        indices.push({ start: startIndex, end: endIndex, tag: theme, text: result, color });
                    }
                }
            } else {
                const categoryArray = this.state[category];
                console.log(categoryArray)
                for (const substring of categoryArray) {
                    const sent = substring[prop];
                    const result = sent.trim()
                    const startIndex = string.indexOf(result);
                    if (startIndex !== -1) {
                        const endIndex = startIndex + result.length;
                        indices.push({ start: startIndex, end: endIndex, tag, text: result, color });
                    }
                }
            }
        }

        return indices;
    };


    generateAnnotationsOptions = () => {
        const modelOptions = [
            { value: 'Recommendation', label: 'Recommendation', isFixed: true },
            { value: 'Agreement', label: 'Agreement', isFixed: true },
            { value: 'Clarification', label: 'Clarification', isFixed: true },
            { value: 'Disagreement', label: 'Disagreement', isFixed: true },
        ];

        let themeOptions = [];
        if (this.state.themes.length !== 0) {
            themeOptions = [
                { value: 'placeholder', label: 'Thematic Groups', isDisabled: true },
                ...this.state.selectableThemes.map((value, index) => ({
                    value,
                    label: value,
                    isFixed: true
                }))
            ];
        }
        const allTags = [...modelOptions, ...themeOptions];
        //This is what shows up for options in the annotation box.
        this.setState({ creatableDefaultOptions: allTags })
    }

    escapeRegExp(str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }

    codyHighlight = (string) => {
        const lowerCaseText = string.toLowerCase()
        console.log('codyHighlight function called');
        // Assuming you have already defined `wordsForHighlight` array as you mentioned earlier
        const wordsForHighlight = this.props.filterText.toLowerCase().split(',').map((word) => word.trim());
        console.log(wordsForHighlight)

        const highlightCategories = wordsForHighlight.map((word) => ({
            category: 'keyword',
            tag: 'Keyword',
            color: '#FFFACD',
            prop: word,
        }));

        console.log(highlightCategories)

        const indices = [];
        const color = '#FFFACD';

        let startIndex = 0;

        while (startIndex < lowerCaseText.length) {
            let matchedWord = null;

            // Check if any word from wordsForHighlight matches the current substring starting from startIndex
            for (const { prop } of highlightCategories) {
                if (lowerCaseText.startsWith(prop, startIndex)) {
                    matchedWord = prop;
                    break;
                }
            }

            if (matchedWord) {
                const endIndex = startIndex + matchedWord.length;

                // Check if the object with the same text, start, and end already exists

                // If the object is not a duplicate, add it to the indices array
                indices.push({
                    tag: 'Keyword',
                    color: color,
                    key: uuidv4(),
                    text: matchedWord,
                    start: startIndex,
                    end: endIndex,
                });


                // Move to the next character after the matched word
                startIndex = endIndex;
            } else {
                // If no match is found, move to the next character
                startIndex++;
            }
        }

        // Now `indices` array contains the unique occurrences of the words from `wordsForHighlight` in the `string`


        console.log(indices)

        const uniqueObjectsSet = new Set(indices.map(({ key, ...rest }) => JSON.stringify(rest)));

        // Convert the set back to an array to get the final list of unique objects
        const uniqueObjects = Array.from(uniqueObjectsSet, (objString) => JSON.parse(objString));

        console.log(uniqueObjects);

        this.setState((prevState) => ({
            value: [...prevState.value, ...uniqueObjects], // Add the indices array to the existing values array
        }));
    }



    handleTabChange = (tab) => {
        console.log(this.state.ariaLiveMessage)
        const { selectedTabs } = this.state;
        const isSelected = selectedTabs.includes(tab);
        if (isSelected) {
            this.setState({
                selectedTabs: selectedTabs.filter((selectedTab) => selectedTab !== tab)
            });
            this.updateAriaLiveRegion(tab + ' tab unselected')

        } else {
            this.setState({
                selectedTabs: [...selectedTabs, tab]
            });
            this.updateAriaLiveRegion(tab + ' tab selected')

        }
    };

    handleDropdownChange = (selectedValue) => {
        const selectedOptions = this.state.selectedOptions.map(info => info.value)
        const tagValues = selectedValue.map(info => info.value);
        const tagValuess = tagValues.map(str => str + 's');

        const difference = selectedOptions.filter((option) => !tagValues.includes(option));
        if (difference.length === 0) {
            this.setState((prevState) => ({
                selectedOptions: selectedValue,
                selectedHighlightTags: tagValues,
                selectedTabs: [...prevState.selectedTabs, ...tagValuess],
            }));
        }
        else {
            console.log('Items are being removed')
            if (difference.length !== 0) {
                const filteredObjects = this.state.value.filter(item => difference.includes(item.tag));
                if (filteredObjects.length === 0) {
                    const pluralizedArray = difference.map((option) => option + 's');
                    const notFoundInSelectedTabs = this.state.selectedTabs.filter((option) => !pluralizedArray.includes(option));
                    this.setState({
                        selectedOptions: selectedValue,
                        selectedHighlightTags: tagValues,
                        selectedTabs: notFoundInSelectedTabs
                    })
                }
                else {
                    this.setState({
                        selectedOptions: selectedValue,
                        selectedHighlightTags: tagValues
                    })
                }
            }
        }
    };

    setThematicGroups = (e) => {
        //This function is triggered by making a change in the thematic groups dropdown.
        const { selectedTabs } = this.state;

        if (e.target.value === 'All') {
            if (e.target.checked === true) {
                this.setState({
                    selectedTabs: [...selectedTabs, ...this.state.selectableThemess]
                })
            }
            else if (e.target.checked === false) {
                const noThemesArray = this.state.selectedTabs.filter(tab => !this.state.selectableThemess.includes(tab));
                console.log(noThemesArray)
                this.setState({
                    selectedTabs: noThemesArray
                })
            }
        }
        else {
            if ((e.target.value !== 'All') && (e.target.checked === true)) {
                const singleValueArray = [e.target.value + 's'];
                this.setState({
                    selectedTabs: [...selectedTabs, ...singleValueArray]
                })
            }
            else if (e.target.value !== 'All' && e.target.checked === false) {
                const singleValueArray = e.target.value + 's';
                const removeThemeArray = this.state.selectedTabs.filter(item => item !== singleValueArray);
                this.setState({
                    selectedTabs: removeThemeArray
                })
            }
        }
    }

    filterValue = (value) => {

        const modifiedTabs = this.state.selectedTabs.map((tag) => tag.slice(0, -1));
        console.log(modifiedTabs)

        const filteredData = value.filter((obj) =>
                modifiedTabs.some((tag) => tag.toLowerCase() === obj.tag.toLowerCase())
            );

        
    
        const updatedNonKeywordData = filteredData
            .filter(obj => obj.tag !== 'Keyword')
            .map((obj, index, arr) => {
                let updatedObj = { ...obj };
                arr.slice(0, index).forEach(prevObj => {
                    if (prevObj.text === obj.text && prevObj.end > updatedObj.start) {
                        updatedObj.start = prevObj.end;
                    }
                });
                return updatedObj;
            });

            console.log(updatedNonKeywordData)

    
        const updatedKeywordData = filteredData
            .filter(obj => obj.tag === 'Keyword')
            .map(keywordObj => {
                let updatedObj = { ...keywordObj };
                updatedNonKeywordData.forEach(nonKeywordObj => {
                    if (nonKeywordObj.text.includes(keywordObj.text) && nonKeywordObj.end > updatedObj.start) {
                        updatedObj.start = nonKeywordObj.end;
                        updatedObj.end = nonKeywordObj.end;
                    }
                });
                return updatedObj;
            });

            function removeKey(obj) {
                const { key, ...rest } = obj;
                return rest;
            }

            console.log(updatedKeywordData)
            const uniqueUpdatedKeywordData = Array.from(new Set(updatedKeywordData.map(obj => JSON.stringify(removeKey(obj)))), JSON.parse);
            console.log(uniqueUpdatedKeywordData)

    
            const combinedArray = [...updatedNonKeywordData, ...uniqueUpdatedKeywordData];

            console.log(combinedArray)


            
            return combinedArray;
    };

    // filterValue = (value) => {

    //     function isUnique(item, index, arr) {
    //         return arr.findIndex((elem) => elem.text === item.text) === index;
    //     }

    //     function findObjectsWithSameText(text) {
    //         return filteredData.filter((item) => item.text === text);
    //     }

    //     const modifiedTabs = this.state.selectedTabs.map((tag) => tag.slice(0, -1));
    //     console.log(modifiedTabs)
    //     const filteredData = value.filter((obj) =>
    //         modifiedTabs.some((tag) => tag.toLowerCase() === obj.tag.toLowerCase())
    //     );
    //     console.log(filteredData)
    //     const filteredDataWithoutHighlight = filteredData.filter((obj) => obj.tag !== 'Keyword');
    //     const temp = filteredData.filter((obj) => obj.tag === 'Keyword');
    //     const uniqueObjectsSet = new Set(temp.map(({ key, ...rest }) => JSON.stringify(rest)));
    //     const filteredDataWithHighlight = Array.from(uniqueObjectsSet, (objString) => JSON.parse(objString));

    //     const uniqueData = filteredDataWithoutHighlight.filter(isUnique);
    //     console.log(uniqueData)
    //     const updatedArray = [];

    //     uniqueData.forEach((obj) => {
    //         const objectsWithSameText = findObjectsWithSameText(obj.text);

    //         objectsWithSameText.forEach((item, index) => {

    //             if (index !== 0) {
    //                 item = JSON.parse(JSON.stringify(item));
    //                 item.start = objectsWithSameText[index - 1].end;
    //             }
    //             updatedArray.push(item);
    //         });
    //     });
    //     console.log(`Object array recalculated for ${JSON.stringify(this.state.selectedTabs)}`)
    //     const combinedArray = [...filteredDataWithHighlight, ...updatedArray];
    //     console.log(combinedArray)


    //     return combinedArray;
    // };


    filterDARC = (allObjs) => {
        const filteredObjs = allObjs.filter((obj) => obj.tag !== 'Keyword');
        const arr = filteredObjs.filter(item => item.color === TAG_COLORS.Theme);
        return arr
    }

    filterThemes = (allObjs) => {
        const filteredObjs = allObjs.filter((obj) => obj.tag !== 'Keyword');
        const arr = filteredObjs.filter(item => item.color !== TAG_COLORS.Theme);
        return arr
    }

    handleStateChange(newValue) {
        console.log('HandleStateChange Fired')
        const themeObjs = this.filterDARC(this.state.value)

        const themesArray = themeObjs.map((item) => item.tag);
        const themessArray = themeObjs.map((item) => item.tag + 's');

        const uniqueThemesTagArray = [...new Set(themesArray)];
        const pluralThemesTagsArray = [...new Set(themessArray)];

        const stateUpdatePromise = new Promise((resolve) => {
            console.log("The state was changed")

            this.setState({
                value: newValue,
                recommendations: this.getDARCValues("Recommendation", newValue),
                agreements: this.getDARCValues("Agreement", newValue),
                disagreements: this.getDARCValues("Disagreement", newValue),
                clarifications: this.getDARCValues("Clarification", newValue),
                themes: themeObjs,
                selectableThemes: uniqueThemesTagArray,
                selectableThemess: pluralThemesTagsArray
            }, () => {
                this.generateAnnotationsOptions();
                this.createThemesCheckBoxes(uniqueThemesTagArray);

                resolve();
            });

        });

        stateUpdatePromise.then(() => {
            console.log("state has changed" + JSON.stringify(newValue));

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    docketId: this.props.docketId,
                    comment_id_att: this.props.commentId + '_' + this.props.attNum,
                    comment_id: this.props.commentId,
                    attNum: this.props.attNum,
                    user: this.props.user,
                    feedback: JSON.stringify(this.filterThemes(this.state.value)),
                    themes: JSON.stringify(this.filterDARC(this.state.value)),
                })
            };

            fetch('/api/add_darc_feedback', requestOptions)
                .then((response) => response.json())
                .then((json) => {
                    if (this._isMounted) {
                        console.log(json);
                    }
                });
        });
    }

    handleRemove = (obj_text, obj_tag) => {
        console.log("removing" + obj_text + ',' + obj_tag)
        let allObjsArray = this.state.value
        const objDupArray = allObjsArray.filter(item => item.text !== obj_text || item.tag !== obj_tag);
        console.log(objDupArray)
        this.setState({ value: objDupArray }, () => {
            this.handleStateChange(this.state.value);
        });
    }

    createThemesCheckBoxes = (themeObjs) => {
        console.log(themeObjs)

        if (themeObjs) {
            return themeObjs.map((theme, index) => {
                const count = this.state.value.filter(obj => obj.tag === theme).length;
                const isDisabled = count === 0;
                const isChecked = this.state.selectedTabs.includes(theme + 's');
                return (
                    <React.Fragment key={`${theme}-${index}`}>
                        <Form.Check
                            style={{ marginLeft: '5%' }}
                            disabled={isDisabled}
                            type="checkbox"
                            label={theme}
                            value={theme}
                            onChange={e => {
                                this.setThematicGroups(e);
                            }}
                            checked={isChecked}
                        />
                    </React.Fragment>
                );
            });
        } else {
            console.log('Something went wrong with the themes checkbox creation.');
        }
    };

    findUniqueObject(firstArray, secondArray) {
        for (const object of firstArray) {
            const isUnique = secondArray.every((item) => item.key !== object.key);
            if (isUnique) {
                return object;
            }
        }
        return null;
    }

    tempFunction = (modelObjsArray) => {
        function compareObjects(obj1, obj2) {
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);

            if (keys1.length !== keys2.length) {
                return false;
            }

            for (const key of keys1) {
                if (
                    key !== "key" && key !== "textDupIndex" && key !== "start" && key !== "end" && obj1[key] !== obj2[key]
                ) {
                    return false;
                }
            }
            return true;
        }

        if (modelObjsArray === undefined) {
            //This can happen when items are highlighted that are new or old and new to be deleted.
            const newObjs = this.state.onChangeInfo.filter((obj1) => {
                return !this.state.value.some((obj2) => {
                    return obj1.text === obj2.text && obj1.tag === obj2.tag;
                });
            });

            if (newObjs.length !== 0) {
                console.log('Objs need to be added.')
                const filteredNewObjs = newObjs.filter(obj => obj.text.trim() !== '');

                const multipleTagArray = filteredNewObjs.flatMap((item) => {

                    return this.state.selectedHighlightTags.map((tag) => {
                        const color = TAG_COLORS[tag] || '#E7E9FA';

                        return {
                            ...item,
                            tag: tag,
                            color: color,
                            key: uuidv4(),
                        };
                    });
                });

                const nonDuplicateNewObjs = multipleTagArray.filter(obj =>
                    !this.state.value.some(stateObj =>
                        compareObjects(obj, stateObj)
                    )
                );

                console.log(nonDuplicateNewObjs)
                if (nonDuplicateNewObjs.length !== 0) {
                    this.setState((prevState) => ({
                        value: [...nonDuplicateNewObjs, ...prevState.value],
                    }),
                        () => {
                            this.handleStateChange(this.state.value)
                        }


                    );

                }
            }
            else if (newObjs.length === 0) {
                console.log('Objs need to be deleted')
                let filterValue = this.filterValue(this.state.value)
                const filterValueState = filterValue.filter((item) => item.tag !== 'Keyword');
                const AllObjsHaveKey = filterValueState.every((item) => item.key !== '');
                if (AllObjsHaveKey === true && filterValueState.length !== 0) {
                    const uniqueObject = this.findUniqueObject(filterValueState, this.state.onChangeInfo);
                    let { text: deleteobjtext } = uniqueObject;
                    let selectedtagstring = this.state.extractedTag

                    this.setState((prevState) => {
                        const updatedValue = prevState.value.filter(
                            (object) => !(object.tag === selectedtagstring && object.text === deleteobjtext)
                        );
                        return { value: updatedValue };
                    }, () => {
                        this.handleStateChange(this.state.value);
                    });
                }

            }
            this.setState({ isprocessing: false })
        }

        const AllNewObjsHaveText = modelObjsArray.filter((item) => item.text !== '')
        if (AllNewObjsHaveText.length !== 0) {
            const AllObjsHaveTag = AllNewObjsHaveText.filter((item) => item.tag !== '')

            if (AllObjsHaveTag.length !== 0) {
                console.log('All Items have tags and need to be added to this.state.value.')
                const uniqueData = Array.from(
                    new Map(AllObjsHaveTag.map(obj => [JSON.stringify(obj), obj])).values()
                );
                const multipleTagArray = uniqueData.map((item) => {

                    return {
                        ...item,
                        key: uuidv4(),
                    };
                });
                const nonDuplicateNewObjs = multipleTagArray.filter(obj =>
                    !this.state.value.some(stateObj =>
                        compareObjects(obj, stateObj)
                    )
                );
                console.log(nonDuplicateNewObjs)
                if (nonDuplicateNewObjs.length !== 0) {
                    this.setState((prevState) => ({
                        value: [...prevState.value, ...nonDuplicateNewObjs],
                        isprocessing: false
                    }));
                }
            }

        }
    }

    handleContainerClick = (event) => {
        event.stopPropagation();
        this.handleTagClickPromise(event)
            .then(() => {
                console.log('Promise completed')
                this.tempFunction();
            })
            .catch((error) => {
                console.log('I have an error with the promise.')
            });
    };

    findTagInText = (text) => {
        const { creatableDefaultOptions } = this.state;
        const allAppliableObjs = creatableDefaultOptions.filter(option => option.label !== "Thematic Groups");
        const allAppliableTags = allAppliableObjs.map(info => info.value);
        const foundTag = allAppliableTags.find((tag) => text.includes(tag));
        return foundTag || 'dummyPlaceholder';
    }

    handleTagClickPromise = (event) => {
        return new Promise((resolve, reject) => {
            console.log(event);
            let selectedtagstring = event.target.innerText;
            let extractedWord = this.findTagInText(selectedtagstring);
            console.log(extractedWord)
            this.setState({ extractedTag: extractedWord });
            resolve();
        });
    }


    handleChange = (newValue) => {
        //To ensure this function does not fire twice while processing.
        if (this.state.isprocessing === false) {
            this.setState({
                onChangeInfo: newValue,
                isprocessing: true
            });
        }
    };

    createThemeDropdownItem = (newOption) => {
        const updatedTagList = newOption + 's'
        const newCreatableObj = { value: newOption, label: newOption, isFixed: true };
        const newThemeObj = { theme: newOption }
        this.setState(prevState => ({
            selectedOptions: [...prevState.selectedOptions, newCreatableObj],
            themes: [...prevState.themes, newThemeObj],
            selectedHighlightTags: [...prevState.selectedHighlightTags, newOption],
            selectedTabs: [...prevState.selectedTabs, updatedTagList],
            selectableThemes: [...prevState.selectableThemes, newOption],
            selectableThemess: [...prevState.selectableThemess, updatedTagList]
        }), () => {
            this.generateAnnotationsOptions();
        });
    }

    filterUniqueThemes = (themeObjs) => {
        const uniqueThemeObjs = Object.values(themeObjs.reduce((acc, obj) => {
            acc[obj.theme] = obj;
            return acc;
        }, {}));
        return uniqueThemeObjs.length
    }
    handleRemove(event) {
		if (event.key === 'Enter') {
		  // Simulate a click event on the ClearIndicator element
		  event.currentTarget.click();
		}
	  }
      ClearIndicator = (props) => {
        const { innerProps } = props;
        return (
          <components.ClearIndicator {...props}>
            {/* Use a button element with an onClick event */}
            <button
              type="button"
              className="invisible-button-filter"
              aria-label="Remove all selections"
              tabIndex={0}
              onClick={props.clearValue}
              onKeyPress={this.handleRemove}
              {...innerProps}
            >
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-8mmkcg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </button>
          </components.ClearIndicator>
        );
      };
    

    render() {


        // console.log(this.state.value)
        // console.log(this.state.themes)
        // console.log(this.state.selectedTabs)
        // console.log(this.state.selectedHighlightTags)
        // console.log(this.state.selectedOptions)
        // console.log(this.state.cmt)
        // console.log(this.state.cmt.comment)

        console.log("aria-LIVE MESSAGE HERE" + this.state.ariaLiveMessage)



        const isThemeSelected = this.state.selectableThemess.find(theme => this.state.selectedTabs.includes(theme));
        const dropdownStyle = isThemeSelected ? { outline: '3px solid #303881', backgroundColor: '#E7E9FA', borderColor: '#E7E9FA', fontWeight: 'bold' } : { backgroundColor: '#F0F0F0', outline: '2px solid #303881', borderColor: '#E7E9FA' };

        const tabCheckboxData = [
            {
                label: "Disagreements",
                onChange: this.handleTabChange,
                items: this.state.value.filter(obj => obj.tag === "Disagreement"),
                backgroundColor: "#F8DFE2",
            },
            {
                label: "Agreements",
                onChange: this.handleTabChange,
                items: this.state.value.filter(obj => obj.tag === "Agreement"),
                backgroundColor: "#DBF3DF",
            },
            {
                label: "Recommendations",
                onChange: this.handleTabChange,
                items: this.state.value.filter(obj => obj.tag === "Recommendation"),
                backgroundColor: "#E1F3F8",
            },
            {
                label: "Clarifications",
                onChange: this.handleTabChange,
                items: this.state.value.filter(obj => obj.tag === "Clarification"),
                backgroundColor: "#F2E4D4",
            }
        ];
        

        const { selectedTabs } = this.state;

       

        class TabCheckbox extends Component {
            handleTabChange = (label) => {
                this.props.onChange(label);
            };

            render() {
                const { label, items } = this.props;
                const selected = selectedTabs.includes(label);
                const hasItems = items !== undefined;

                return (
                    <>
                    
                    <Button
                        role="button"   // To indicate the role of this element
                        tabIndex="0"    // To make it focusable
                        variant="outline-info"
                        disabled={items.length === 0}
                        onClick={() => this.handleTabChange(label)}
                        onKeyPress={(e) => {  // Listening for key press events
                            if (e.key === "Enter" || e.key === " ") {
                                this.handleTabChange(label);
                                e.preventDefault();  // Prevent the default action (like page scrolling for space key)
                            }
                        }}
                        className={selected ? `tab-checkbox-${label} active tab-checkbox` : `tab-checkbox-${label} tab-checkbox`}
                        style={{ display: 'flex' }}

                    >
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {label}
                            {hasItems && <Badge bg='' style={{ marginLeft: '3px', backgroundColor: '#162E51', color: '#ffffff' }}>{items.length}</Badge>}
                        </span>
                    </Button>
  {
                    items.length===0 && (
                        <span className="sr-only">
                            {label} instances unavailable or unidentified
                        </span>
                    )
                }

                </>
                    
                );

            }
        }

        var truncMsg = this.state.cmt.truncated ? "This comment attachment was truncated for length." : "";
        let attachmentButtonDisable = !this.props.hasSupportedFile
        console.log(attachmentButtonDisable)            
        if (this.state.cmt.comment !== undefined) {

            return (
                <>
                  <div className="sr-only" aria-live="assertive" id="aria-live-region">
                    {this.state.ariaLiveMessage}
                </div>
                    <div>
                        <Button variant="outline-secondary" size="sm" tabIndex="0" onClick={() => this.props.setShowPdf()} aria-label={attachmentButtonDisable ? "PDF Unavailable for this attachment" : ""} disabled={attachmentButtonDisable}>Show PDF</Button>
                    </div>
                    <div className="tabs-container">

                        {tabCheckboxData.map((tabCheckbox, index) => (
                            <TabCheckbox
                                key={index}
                                label={tabCheckbox.label}
                                onChange={tabCheckbox.onChange}
                                items={tabCheckbox.items}
                                backgroundColor={tabCheckbox.backgroundColor}
                            />

                        ))}
                        <Dropdown autoClose="outside">
                            <Dropdown.Toggle style={dropdownStyle} variant='info' disabled={this.state.themes.length === 0}>
                                Thematic Groups
                                <Badge bg='' style={{ marginLeft: '8px', marginRight: '8px', backgroundColor: '#303881', color: '#ffffff' }}>{this.state.selectableThemes.length}</Badge>                                </Dropdown.Toggle>

                            <Dropdown.Menu style={{ maxHeight: '740px', overflowY: 'auto' }}>
                                <Form.Check
                                    style={{ marginLeft: '5%' }}
                                    checked={this.state.selectableThemess.every(theme => this.state.selectedTabs.includes(theme))}
                                    type="checkbox"
                                    label="Show All"
                                    value={"All"}
                                    onChange={e => this.setThematicGroups(e)}
                                />
                                <Dropdown.Divider />
                                <Dropdown.Header>Show Only</Dropdown.Header>
                                {this.createThemesCheckBoxes(this.state.selectableThemes)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <div className="tabs-container" style={{ marginBottom: '16px' }}>
                        <CreatableSelect
                            tabSelectsValue={false}

                            className="flex-grow-1"
                            value={this.state.selectedOptions}
                            onChange={this.handleDropdownChange}
                            onCreateOption={this.createThemeDropdownItem}
                            isDisabled={this.state.creatableDefaultOptions.length === 0}
                            isLoading={this.state.creatableDefaultOptions.length === 0}
                            isSearchable
                            isMulti
                            closeMenuOnSelect={false}
                            options={this.state.creatableDefaultOptions}
                            placeholder={this.state.annotationPlaceholder}
                            styles={{
                                multiValue: (provided, state) => {
                                    let backgroundColor = '';

                                    if (
                                        state.data.label === 'Recommendation' ||
                                        state.data.label === 'Agreement' ||
                                        state.data.label === 'Clarification' ||
                                        state.data.label === 'Disagreement'
                                    ) {
                                        backgroundColor = state.data.label === 'Recommendation' ? TAG_COLORS.Recommendation :
                                            state.data.label === 'Agreement' ? TAG_COLORS.Agreement :
                                                state.data.label === 'Clarification' ? TAG_COLORS.Clarification :
                                                    TAG_COLORS.Disagreement;
                                    } else {
                                        backgroundColor = TAG_COLORS.Theme;
                                    }

                                    return {
                                        ...provided,
                                        backgroundColor,
                                        color: 'black', // Set the text color for tags
                                        borderRadius: '3px' // Add border radius for tags
                                    };
                                },
                            }}
                            components={{
                                MultiValueRemove: (props) => (
                                  <button
                                    tabIndex="0"	
                                    id="remove-selection"
                                    className="invisible-button"
                                    aria-label="Remove selection"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      props.innerProps.onClick(e);
                                    }}
                                  >
                                    <components.MultiValueRemove {...props} />
                                  </button>
                                ),
                                ClearIndicator: this.ClearIndicator
                                ,
                                
        
        
                              }}
                        />
                    </div>

                    <span style={{ padding: "8px", display: "inline" }}><h2 style={{fontSize: "16pt", fontWeight: "bold"}}>{this.props.commentId} Attachment: {this.props.attNum}</h2><div className="truncated">{truncMsg}</div></span>

                    <Split className="split" >
                        <div className="scroll-box-big">
                            <div />

                            <div onClick={this.handleContainerClick}>

                                <TextAnnotator
                                    style={{
                                        lineHeight: 1.5,
                                    }}
                                    content={this.state.cmt.comment}
                                    value={this.filterValue(this.state.value)}
                                    onChange={this.handleChange}
                                    getSpan={span => ({
                                        ...span,
                                    })}
                                    editable

                                />

                            </div>
                        </div>


                        <div className="scroll-box-big">
                            <header style={{ backgroundColor: "#005EA2", color: "white", textAlign: "center", borderRadius: "10px" }}>
                                <h3 style={{ fontSize: "16pt",padding: "5px", fontWeight: "bold", fontFamily: "Public Sans, sans-serif" }}>Annotations</h3>
                            </header>
                            <div style={{ fontSize: 12, lineHeight: 1.2 }}>
                                {Array.from(new Set(this.filterValue(this.state.value).filter(item => item.tag !== 'Keyword').map(item => item.text))).map((text, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            borderLeft: "1px solid black",
                                            padding: "10px",
                                            overflowWrap: "break-word",
                                            marginBottom: "10px"
                                        }}
                                    >
                                        <p style={{ fontSize: "14px" }}>{text}</p>
                                        {this.filterValue(this.state.value)
                                            .filter(obj => obj.text === text)
                                            .map((obj, innerIndex) => (
                                                <p key={`${obj.text}-${obj.tag}`} style={{ fontWeight: "bold", fontSize: "14px" }}>
                                                    <button
                                                        style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
                                                        aria-label={`Remove ${obj.tag + obj.text} ` }
                                                        onClick={(e) => this.handleRemove(obj.text, obj.tag)}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 20 20">
                                                            <title>Remove</title>
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg>
                                                    </button>
                                                    {obj.tag}
                                                </p>
                                            ))}
                                    </div>
                                ))}
                            </div>
                        </div>

                    </Split>

                    <div style={{ paddingBottom: "50px" }}></div>
                </>
            );
        } else {
            return (
                <div style={{ marginTop: "10%" }}>
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                </div>
            );
        }


    }
}
export default ModelingTabs;